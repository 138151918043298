import './App.css';
import { useState } from 'react';
import {
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import { Menubar } from "primereact/menubar";
import 'primeicons/primeicons.css'

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";


import Home from "./Home";
import Writer from "./Writer";
import Register from './RegistrationForm';
import Login from './Login';
import GeneratedListingsPage from './GetGenerated';

export default function App() {

  const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt') || '');


  const handleLogout = () => {
    // Remove token from state and localStorage
    setJwtToken('');
    localStorage.removeItem('jwt');
  };

  const items = [
    {
      label: "Home",
      icon: "pi pi-fw pi-home",
      command: () => {
        window.location = "/";
      },
    },
    {
      label: "Writer",
      icon: "pi pi-fw pi-pencil",
      command: () => {
        window.location = "/writer";
      },
    },
    // {
    //   label: "About",
    //   icon: "pi pi-fw pi-info",
    //   command: () => {
    //     window.location = "/about";
    //   },
    // },
    // {
    //   label: "Contact",
    //   icon: "pi pi-fw pi-envelope",
    //   command: () => {
    //     window.location = "/contact";
    //   },
    // },
    // {
    //   label: "Login",
    //   icon: "pi pi-fw pi-user",
    //   command: () => {
    //     window.location = "/login";
    //   },
    // },
  ];

  // Conditionally add "Login" or "Logout" based on token presence
  if (jwtToken) {
    items.push(
      {
        label: "Logout",
        icon: "pi pi-fw pi-user-minus",
        command: handleLogout
      },
      {
        label: "Generated Listings",
        icon: "pi pi-fw pi-list",
        command: () => {
          window.location = "/generated";
        }
      },

    );
  } else {
    items.push(
      {
        label: "Register",
        icon: "pi pi-fw pi-user-plus",
        command: () => {
          window.location = "/register";
        }
      },
      {
        label: "Login",
        icon: "pi pi-fw pi-user",
        command: () => {
          window.location = "/login";
        }
      });
  }


  return (
    <BrowserRouter>
      <div><Menubar model={items}></Menubar>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/writer" element={<Writer />} />
          {/* <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} /> */}
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/generated" element={<GeneratedListingsPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App />);