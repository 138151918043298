import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Badge } from "primereact/badge";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Card } from "primereact/card";
import './Home.css'; // Don't forget to import your CSS
import './Results'; // Don't forget to import your CSS
import './Results.css'; // Don't forget to import your CSS
import Results from "./Results";


const Home = () => {
    const [records, setRecords] = useState([]);
    const [globalFilterValue1, setGlobalFilterValue1] = useState("");
    //  TODO - Rename this variable to something more meaningful - it isn't a "result" but is a "Listing" or entry from the OpenAI API response, it is generated content
    const [results, setResults] = useState(null);
    const [filters1, setFilters1] = useState({});
    const header1 = () => {
        return (
            <div className="flex justify-content-between">
                {/* <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          className="p-button-outlined"
          onClick={clearFilter1}
        /> */}
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        value={globalFilterValue1}
                        onChange={onGlobalFilterChange1}
                        placeholder="MLS ID / Keyword Search"
                    />
                </span>
            </div>
        );
    };

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1["global"].value = value;

        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    };

    // const initFilters1 = () => {
    //     setFilters1({
    //         global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //         storyId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     });
    //     setGlobalFilterValue1("");
    // };
    // const handleLoadStories = async () => {
    //     // setIsLoading(true);
    //     try {
    //         const response = await axios.get(
    //             "https://dev.mlswriter.pro/api/listings"
    //         );
    //         setRecords(response.data.lastUpdatedRecords);
    //     } catch (err) {
    //         // setError(err);
    //         console.log(err);
    //     }
    //     // setIsLoading(false);
    // };

    const handleLoadStory = async (id) => {
        // setIsLoading(true);
        try {
            const response = await axios.get(
                `https://dev.mlswriter.pro/api/listings/${id}`
            );
            console.log(response.data.results);
            setResults(response.data.results);
        } catch (err) {
            // setError(err);
            console.log(err);
        }
        // setIsLoading(false);
    };

    const jsonData = {
        "results": [
            // ... Your data objects here
        ]
    };



    // const [isLoading, setIsLoading] = useState(true);
    return (
        <div>
            <div className="home-container">
                <div className="hero-banner">
                    <h1>Welcome to MLS Writer Pro</h1>
                    <p>Your Ultimate Assistant for Real Estate Listings and Marketing</p>
                </div>

                <div className="intro-content">
                    <h2>Powered by AI</h2>
                    <p>MLS Writer Pro leverages the power of GPT-4, the state-of-the-art Artificial Intelligence technology from OpenAI, to help you create compelling MLS listings, write captivating marketing copy, and craft engaging social media posts.</p>
                    <p>With MLS Writer Pro, you save time, increase productivity, and enhance the quality of your content. Our AI-powered writer ensures you always have the right words to showcase your properties and engage your audience.</p>
                    <p>Whether you're a real estate agent, a marketing team member, or a social media manager, MLS Writer Pro is your reliable assistant, ready to help you create standout content.</p>
                    <h2>Get Started Now</h2>
                    <p>Ready to transform your real estate content? Click the button below to start using MLS Writer Pro now.</p>
                    <Link to="/writer" className="start-btn">Start Writing</Link>
                </div>
            </div>
            <br />
            <hr />
        </div>
    );
};

export default Home;
