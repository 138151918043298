import React, { useState, useEffect, useRef } from 'react';
// import { Panel } from 'primereact/panel';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
import axios from 'axios';
// import { v4 as uuidv4 } from 'uuid';
// import { Steps } from 'primereact/steps';
import { Icon } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
// import { Skeleton } from 'primereact/skeleton';
// import { Navigate, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Link, Navigate, useNavigate } from 'react-router-dom';



const Register = () => {
    const [hasSubmitted, setSubmitted] = useState(false); // Step 1
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState(null);
    const [confirmedPassword, setConfirmedPassword] = useState(null)
    const [passwordMismatch, setPasswordMismatch] = useState(true);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [ButtonEnabled, setButtonEnabled] = useState(false);
    const toast = useRef(null);
    const navigate = useNavigate()

    const validateEmail = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        setInvalidEmail(!validateEmail(value));
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordMismatch(confirmedPassword !== e.target.value);
    };

    const handleConfirmedPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmedPassword(value);
        setPasswordMismatch(value !== password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create the user object
        const user = {
            email: email,
            password: password
        };

        try {
            // Replace with your backend URL
            // const backendUrl = '/api';

            // Send POST request to register route
            const response = await axios.post('https://dev.mlswriter.pro/api/register/', user);
            const { jwt } = response.data;
            localStorage.setItem('jwt', jwt); // Store the JWT in localStorage
            // Handle response as needed (e.g., show success message, redirect)
            // Store the username as well
            const username = user.email; // Replace with the actual username source
            localStorage.setItem('username', username); // Store the username in localStorage
            //
            console.log(response.data.message);
            // In your JSX
            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: (
                    <div>
                        Registration successful. <Link to="/writer">Go to Writer</Link>
                    </div>
                )
            });
            // navigate('/Writer')
        } catch (error) {
            // Handle error (e.g., show error message)
            console.error('Registration failed:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: (
                    <div>
                        Error registering. {error.response.data.detail}
                    </div>
                )
            });
        }
    };


    const isButtonEnabled = passwordMismatch && !invalidEmail;


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Card title="Register" style={{ width: '30%' }}>
                <form onSubmit={handleSubmit}>
                    <div className="p-field">
                        <div className="p-grid">
                            <label htmlFor="username" className="p-col-fixed" style={{ width: '100px' }}>
                                Email Address
                            </label>
                            <div className="p-col">
                                <InputText id="username" value={email} onChange={handleEmailChange} />
                            </div>
                        </div>
                        <small id="username-help">
                            Enter your email address, it will be used to log you into the site.
                        </small>
                    </div>
                    <hr />
                    <div className="p-field">
                        <div className="p-grid">
                            <label htmlFor="password" className="p-col-fixed" style={{ width: '100px' }}>
                                Password
                            </label>
                            <div className="p-col">
                                <InputText id="password" type="password" value={password} onChange={handlePasswordChange} />
                            </div>
                        </div>
                        <small id="password-help">
                            Use a strong password that you don't use elsewhere. We cannot guarantee the security of your account without using a strong password.
                        </small>
                    </div>
                    <hr />
                    <div className="p-field">
                        <div className="p-grid">
                            <label htmlFor="confirmPassword" className="p-col-fixed" style={{ width: '100px' }}>
                                Confirm Password
                            </label>
                            <div className="p-col">
                                <InputText id="confirmPassword" type="password" value={confirmedPassword} onChange={handleConfirmedPasswordChange} />
                            </div>
                        </div>
                        <small id="confirm-help">
                            Verify the password entered above to continue.
                        </small>
                        {passwordMismatch && (
                            <small id="confirm-help" className="p-invalid">
                                <p style={{ color: "red" }}>The passwords do not match.</p>
                            </small>
                        )}
                    </div>
                    <Button type="submit" label="Register" disabled={isButtonEnabled} />
                    <Toast ref={toast} />

                </form>
            </Card>
        </div>
    );
};

export default Register;
