import React, { useState, useEffect, useRef } from 'react';
import { Panel } from 'primereact/panel';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Steps } from 'primereact/steps';
import { Icon } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { Skeleton } from 'primereact/skeleton';
import { ProgressBar } from 'primereact/progressbar';
import { MdContentCopy } from 'react-icons/md'; // Import an icon from react-icons
import { CopyToClipboard } from 'react-copy-to-clipboard'; // Import the CopyToClipboard component
import { AutoComplete } from 'primereact/autocomplete';

const AddressInput = () => {
    // Track if he user has searched, found, sent for copywriting the listing
    // This will be used to display a PrimeReact Steps component
    // https://primereact.org/steps/
    const [hasSearched, setHasSearched] = useState(false); // Step 1
    const [hasIndentified, setHasIdentified] = useState(false); // Step 2
    const [hasCopyWritten, setHasCopyWritten] = useState(false); // Step 3
    const [hasCompleted, setHasCompleted] = useState(false); // End State
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedListing, setSelectedListing] = useState(null);
    const [listingDetails, setListingDetails] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const stages = ['Looking Up Address Details', 'Getting Information About the Home', 'Talking to ChatGPT', 'Writing the Listing'];
    const [value, setValue] = useState(0);
    const [stageIndex, setStageIndex] = useState(0);
    // Button To Advance Steps
    // Has a label prop that will change based on the step
    // Has a click handler that will advance the step
    const [buttonLabel, setButtonLabel] = useState('Search');
    const [buttonHandler, setButtonHandler] = useState(null);
    const [token, setToken] = useState(null);
    const [responses, setResponses] = useState([]);

    const jwt = localStorage.getItem('jwt');
    const username = localStorage.getItem('username');
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };
    // const [searchTerm, setSearchTerm] = useState('');
    // const [searchResults, setSearchResults] = useState([]);
    // const [hasSearched, setHasSearched] = useState(false);



    // Search addresses based on the input
    // This function will be called when the user clicks the search button
    // This appears in the first step.
    // const handleSearch = async () => {
    //     try {
    //         // Make an API request to perform the property search based on the input
    //         const response = await axios.get(`/api/addresses?searchTerm=${searchTerm}`);
    //         const fetchedResults = response.data;

    //         if (fetchedResults.length === 0) {
    //             // If the fetched results are empty, display a message
    //             setSearchResults([]);
    //             setHasSearched(true);
    //         } else {
    //             setSearchResults(fetchedResults);
    //             setHasSearched(true);
    //         }
    //     } catch (error) {
    //         console.error('Error performing property search:', error);
    //     }
    // };

    const handleSearch = async (query) => {
        try {
            const response = await axios.get(`/api/addresses?searchTerm=${query}`);
            const fetchedResults = response.data;

            if (fetchedResults.length === 0) {
                setSearchResults([]);
                setHasSearched(true);
            } else {
                setSearchResults(fetchedResults);
                setHasSearched(true);
            }
        } catch (error) {
            console.error('Error performing property search:', error);
        }
    };

    useEffect(() => {
        if (searchTerm.length > 3) {
            handleSearch(searchTerm);
        }
    }, [searchTerm]);

    // Appears in the second step, gets the listing details to be passed to ChatGPT in the Copywrite step.
    // This function will be called when the user selects a listing from the search results
    const handleListingSelect = async (e) => {
        const selectedListing = e.value;
        setSelectedListing(selectedListing);

        try {
            // Fetch additional details using the selected listing's mpr_id
            const response = await axios.get(`/api/listings/?listing_id=${selectedListing.mpr_id}`);
            const fetchedListingDetails = response.data;
            setListingDetails(fetchedListingDetails);
            console.log(fetchedListingDetails);
        } catch (error) {
            console.error('Error fetching listing details:', error);
        }

        setSearchResults([]); // Reset searchResults to dismiss the data table
        setHasIdentified(true);
        setActiveStep(1);
        setToken(uuidv4());
    };

    const items = [
        {
            label: 'Search'
        },
        {
            label: 'Identification'
        },
        {
            label: 'Copywriting'
        },
        {
            label: 'Completion!'
        }
    ];

    useEffect(() => {
        // Reset listing details when a new search is performed
        setListingDetails(null);
    }, [searchTerm]);


    const [isProcessing, setIsProcessing] = React.useState(false);

    // Class for data call to FastAPI in handleSubmit
    // class CopyWrite(BaseModel):
    // token: str
    // username: str
    // jwt: str
    // listingDetails: dict
    // mlslisting: bool
    // blogpost: bool
    // facebook: bool
    // twitter: bool

    // This function will be called when the user clicks the Write Copy button
    // TODO: This needs to consider if the user is generating all forms of copy, MLS Listings, Facebook posts, blog posts, and Twitter/microblogging posts
    const handleSubmit = async () => {

        const jwt = localStorage.getItem('jwt');
        const username = localStorage.getItem('username');
        if (!jwt) {
            // Do something here if the JWT is missing or expired
        }
        else {
            try {
                // Make the API call
                const data = {
                    "token": token,
                    "username": username,
                    "jwt": jwt,
                    "listingDetails": listingDetails,
                    "mlslisting": true,
                    "blogpost": true,
                    "facebook": true,
                    "twitter": true
                }
                const response = await axios.post(`https://dev.mlswriter.pro/api/CopyWrite`, data,
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                setActiveStep(2);
                setIsProcessing(true);
            } catch (error) {
                // ... error handling
            }
        }
    };

    const handleLogin = () => {
        // Perform login logic here
        // Redirect to the login page

    };


    const pollUntilAnswer = async () => {
        return new Promise((resolve) => {
            const startTime = Date.now();
            const interval = setInterval(async () => {
                try {
                    const response = await axios.get(`/api/response/` + token);
                    setResponses(response.data);
                    const elapsedTime = Date.now() - startTime;
                    if (response.data.length > 3 || elapsedTime > 60000) { // 60 seconds = 60000 milliseconds
                        clearInterval(interval); // Stop the polling
                        setActiveStep(3);
                        resolve(); // Resolve the promise
                    }
                } catch (error) {
                    console.error(error);
                }
            }, 7000); // Poll every 7 seconds
        });
    };

    useEffect(() => {
        if (activeStep === 2) {
            pollUntilAnswer();
        }

        return () => {
            // Clean up any ongoing polling or intervals
            // Clear any intervals or cancel any pending requests, if applicable
        };
    }, [activeStep]);

    useEffect(() => {
        const interval = setInterval(() => {
            setValue((prevValue) => {
                if (prevValue < 100) {
                    return prevValue + 20;
                } else {
                    clearInterval(interval); // Stop incrementing when value reaches 100
                    setTimeout(() => {
                        setValue(0);
                        setStageIndex((prevIndex) => (prevIndex + 1) % stages.length);
                    }, 2000);
                    return prevValue;
                }
            });
        }, 2000); // Add 20% every 2 seconds

        return () => {
            clearInterval(interval);
        };
    }, [stageIndex]);


    return (
        <div>
            <Card title="Property Search">
                <Steps model={items} activeIndex={activeStep} readOnly={true} />
                <br />
                {activeStep === 0 && (
                    <div className="p-field">
                        <Card>
                            <label htmlFor="addressInput">Address Search:&nbsp;</label>
                            <InputText
                                id="addressInput"
                                type="text"
                                placeholder="Address Search (Use Address Line 1)"
                                value={searchTerm}
                                onChange={handleInputChange}
                                style={{ width: "50%" }}
                            />
                            {/* <AutoComplete
                                value={searchTerm}
                                suggestions={searchResults}
                                completeMethod={handleSearch}
                                // field="line"
                                onChange={(e) => setSearchTerm(e.value)}
                                onselect={handleListingSelect}
                            /> */}
                        </Card>
                    </div>
                )}
                {(hasSearched && activeStep === 0) && (
                    <div className="p-pt-2">
                        <Card>
                            <h2>Search Results</h2>
                            Select a property from the list below to generate your MLS listing, blog post, and social media posts.
                            <p />
                            If you don't see the property you're looking for, try searching again with additional address information.

                            {searchResults.length === 0 ? (
                                // Render the desired content when there are no search results
                                <div></div>
                            ) : (
                                // Render the desired content when there are search results
                                // For example, you can map over the searchResults array to display each result
                                <DataTable
                                    value={searchResults}
                                    selectionMode="single"
                                    selection={selectedListing}
                                    onSelectionChange={handleListingSelect}
                                >
                                    {/* <Column field="mpr_id" header="ID" /> */}
                                    <Column field="line" header="Address" />
                                    <Column field="city" header="City" />
                                    <Column field="state_code" header="State" />
                                    <Column field="postal_code" header="Zip" />
                                </DataTable>

                            )}
                        </Card>
                    </div>
                )}
                {/* Display selected listing details if available */}
                {listingDetails && (
                    <div>
                        <Card>
                            <h4>Selected Listing:</h4>
                            <div>Street: {listingDetails.street}</div>
                            <div>City: {listingDetails.city}</div>
                            <div>State: {listingDetails.state}</div>
                            <div>Zip: {listingDetails.zip}</div>
                            {/* Add more details as needed */}
                        </Card>
                    </div>
                )}
                <br />
                {activeStep === 0 && (
                    <Button onClick={handleSearch} label="Search Listings" />
                )}
                {username && activeStep === 1 && (
                    <Button onClick={handleSubmit} label="Write Copy" />
                )}
                {activeStep === 1 && !username && (
                    <Button onClick={handleLogin} label="Login to Write Copy" />
                )}
                {activeStep === 2 && (
                    <div>
                        <Button
                            label="Generating"
                            icon="pi spinner"
                            disabled={isProcessing}
                        //onClick={handleProcessing}
                        />
                        <Card>
                            <ProgressBar value={value} style={{ height: '20px' }}></ProgressBar>
                            <div>{stages[stageIndex]}</div>
                        </Card>
                        <Card>
                            <h5>MLS Listing</h5>
                            <Skeleton className="mb-2"></Skeleton>
                            <Skeleton width="10rem" className="mb-2"></Skeleton>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                            <Skeleton height="2rem" className="mb-2"></Skeleton>
                            <Skeleton width="10rem" height="4rem"></Skeleton>
                            <h5>Blog Post Listing</h5>
                            <Skeleton className="mb-2"></Skeleton>
                            <Skeleton width="10rem" className="mb-2"></Skeleton>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                            <Skeleton height="2rem" className="mb-2"></Skeleton>
                            <Skeleton width="10rem" height="4rem"></Skeleton>
                            <h5>Facebook Post Listing</h5>
                            <Skeleton className="mb-2"></Skeleton>
                            <Skeleton width="10rem" className="mb-2"></Skeleton>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                            <Skeleton height="2rem" className="mb-2"></Skeleton>
                            <Skeleton width="10rem" height="4rem"></Skeleton>
                            <h5>Tweet</h5>
                            <Skeleton className="mb-2"></Skeleton>
                            <Skeleton width="10rem" className="mb-2"></Skeleton>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                            <Skeleton height="2rem" className="mb-2"></Skeleton>
                            <Skeleton width="10rem" height="4rem"></Skeleton>
                        </Card>
                    </div>
                )}
                {activeStep === 3 && (
                    <div>
                        {responses.map((entry, index) => (
                            <Card key={index}>
                                {/* Display an icon based on the entry type */}
                                {entry.mls_listing && <h3>MLS Listing <CopyToClipboard text={entry.mls_listing}><MdContentCopy /></CopyToClipboard></h3>}
                                {entry.blog_post && <h3>Blog Post <CopyToClipboard text={entry.blog_post}><MdContentCopy /></CopyToClipboard></h3>}
                                {entry.facebook_post && <h3>Facebook Post <CopyToClipboard text={entry.facebook_post}><MdContentCopy /></CopyToClipboard></h3>}
                                {entry.tweet && <h3>Tweet <CopyToClipboard text={entry.tweet}><MdContentCopy /></CopyToClipboard></h3>}
                                <p style={{ whiteSpace: 'pre-line' }}>
                                    {entry.mls_listing || entry.blog_post || entry.facebook_post || entry.tweet}
                                </p>

                            </Card>


                        ))}
                        <Button label="Start Over" onClick={() => window.location.reload()}></Button>
                    </div>
                )}

            </Card>
        </div>

    );
};

export default AddressInput;
