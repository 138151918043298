import React from 'react';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import './Results.css';
import { useEffect } from 'react';

function Results({ results }) {
    const toast = React.useRef(null);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        toast.current.show({ severity: 'success', summary: 'Copied!', life: 3000 });
    };

    useEffect(() => {
        const textareas = document.querySelectorAll('.copyable-textarea textarea');
        textareas.forEach((textarea) => {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        });
    }, [results]);

    return (
        <>
            <Toast ref={toast} />

            <Panel header="Generated Content">
                <div>
                    {results.map((item, index) => (
                        <div key={index}>
                            {/* <h3>Item {index + 1}</h3> */}

                            {item.mls_listing && (
                                <div>
                                    <h4>MLS Listing</h4>
                                    <div className="copyable-textarea">
                                        <textarea value={item.mls_listing} readOnly />
                                        <Button
                                            icon="pi pi-copy"
                                            onClick={() => copyToClipboard(item.mls_listing)}
                                            className="p-button-rounded p-button-text"
                                        />
                                    </div>
                                </div>
                            )}

                            {item.blog_post && (
                                <div>
                                    <h4>Blog Post</h4>
                                    <div className="copyable-textarea">
                                        <textarea value={item.blog_post} readOnly />
                                        <Button
                                            icon="pi pi-copy"
                                            onClick={() => copyToClipboard(item.blog_post)}
                                            className="p-button-rounded p-button-text"
                                        />
                                    </div>
                                </div>
                            )}

                            {item.tweet && (
                                <div>
                                    <h4>Tweet</h4>
                                    <div className="copyable-textarea">
                                        <textarea value={item.tweet} readOnly />
                                        <Button
                                            icon="pi pi-copy"
                                            onClick={() => copyToClipboard(item.tweet)}
                                            className="p-button-rounded p-button-text"
                                        />
                                    </div>
                                </div>
                            )}

                            {item.facebook_post && (
                                <div>
                                    <h4>Facebook Post</h4>
                                    <div className="copyable-textarea">
                                        <textarea value={item.facebook_post} readOnly />
                                        <Button
                                            icon="pi pi-copy"
                                            onClick={() => copyToClipboard(item.facebook_post)}
                                            className="p-button-rounded p-button-text"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </Panel>
        </>
    );
}

export default Results;
