import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Link } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [loggedIn, setLoggedIn] = useState('');
    const navigate = useNavigate(); // Use useNavigate instead of useHistory

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoginError('');
        try {
            const user = {
                email: email,
                password: password
            };
            // Send POST request to login route
            const response = await axios.post('/api/login', user);
            const { jwt } = response.data;
            localStorage.setItem('jwt', jwt); // Store the JWT in localStorage
            // Store the username as well
            const username = user.email; // Replace with the actual username source
            localStorage.setItem('username', username); // Store the username in localStorage
            //
            // Handle successful login
            console.log(response.data.message);
            setLoggedIn("You're logged in! Proceed to Writer up top.");
            // Navigate to home or any other route to trigger re-render
            navigate('/');
        } catch (error) {
            // Handle login error (e.g., show error message)
            setLoginError('Invalid credentials');
            console.error('Login failed:', error);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Card title="Login" style={{ width: '30%' }}>
                <form onSubmit={handleLogin}>
                    <div className="p-field" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                        <label htmlFor="username">Email Address</label>
                        <InputText id="username" value={email} onChange={handleEmailChange} />
                    </div>
                    <div className="p-field" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                        <label htmlFor="password">Password</label>
                        <InputText id="password" type="password" value={password} onChange={handlePasswordChange} />
                    </div>
                    {loginError && <div style={{ color: 'red' }}>{loginError}</div>}
                    <Button type="submit" label="Login" />
                    {loggedIn && <div style={{ color: 'green' }}>{loggedIn}</div>}
                    {loggedIn && (
                        <Link to="/writer">Start Writing</Link>
                    )}
                    <Toast></Toast>
                </form>
            </Card>
        </div>
    );
};

export default Login;