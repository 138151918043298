import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Modal from 'react-modal';

const App = () => {
    const [listings, setListings] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const username = localStorage.getItem('username'); // Retrieve the username from localStorage

        // Make a POST request with the username
        axios.post('/api/GetGeneratedListings', { username: username })
            .then(response => {
                setListings(response.data.results);
            })
            .catch(error => {
                console.error('Error fetching the listings:', error);
            });
    }, []);

    // Group listings by address and sort by created_at
    const groupedListings = listings.reduce((acc, listing) => {
        const address = listing.address;
        if (!acc[address]) {
            acc[address] = [];
        }
        acc[address].push(listing);
        return acc;
    }, {});

    Object.keys(groupedListings).forEach(address => {
        groupedListings[address].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    });

    // Handle opening the modal
    const openModal = (address) => {
        setSelectedAddress(address);
        setIsModalOpen(true);
    };

    // Handle closing the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedAddress(null);
    };

    return (
        <div>
            <h1>Addresses</h1>
            <ul>
                {Object.keys(groupedListings).map(address => (
                    <li key={address}>
                        <a href="#" onClick={() => openModal(address)}>
                            {address} - {new Date(groupedListings[address][0].created_at).toLocaleString()}
                        </a>
                    </li>
                ))}
            </ul>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Listings Modal"
            >
                <button onClick={closeModal}>Close</button>
                {selectedAddress && (
                    <div className="address-group">
                        <h2>{selectedAddress}</h2>
                        <ul>
                            {groupedListings[selectedAddress].map(listing => (
                                <li key={listing.documents._id} className="listing-item">
                                    <CopyToClipboard text={listing.documents[Object.keys(listing.documents).filter(key => key !== '_id' && key !== 'parent')[0]]}>
                                        <button>Copy to Clipboard</button>
                                    </CopyToClipboard>
                                    <ReactMarkdown>
                                        {listing.documents[Object.keys(listing.documents).filter(key => key !== '_id' && key !== 'parent')[0]]}
                                    </ReactMarkdown>
                                    <p><strong>Created At:</strong> {new Date(listing.created_at).toLocaleString()}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default App;
